
import { useState } from "react";
import { Accordion, Card, Carousel, Col, Container, ListGroup, Navbar, Row } from "react-bootstrap";
import { cookies, stloaded } from "../App";

import Head from "./head";

function Root() {
    const startree = cookies.get('startree');
    return (
        
        <div>
        <Head page='/' />
       
        <Container style={{paddingBottom: '100px'}}>
            <StarNoti content={startree.noti}/>
            <Container>
                <RoomCarousel />
            </Container>     
            <VideoLink />  
            <HowTo />
        </Container>
        
        <Footer />
        
        </div>
        
    );
}

function Footer() {
    return (
        <Container fluid style={{ zIndex: 5, padding: '20px', fontSize: '2vh', background: '#212529', color: 'white', position: 'fixed' , bottom: '0' }}>
            <Row >
                <Col xs='auto' style={{textAlign: 'start'}}>
                    {'별나무합주실'}
                    <span style={{color:'grey'}}>&nbsp;|&nbsp;</span>  
                </Col>
                <Col xs='auto' style={{textAlign: 'start'}}>
                    {'서울시 마포구 서교동 373-6 지층'} 
                    <span style={{color:'grey'}}>&nbsp;|&nbsp;</span>
                    
                </Col>
                <Col sm='auto' style={{textAlign: 'start'}}>
                    {'연락처) 010-3878-6642, 02)323-6642'}
                </Col>
            </Row>
            
           
            
        </Container>
    );
}

function VideoLink() {
    return (
        <>
            <br/>
            <a href="https://youtu.be/NnzkAxlyipc" target="_blank">이용방법 안내영상 보기</a>
            <br/>
        </>
    );
}

function HowTo() {
    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header><span style={{fontWeight: 'bold'}}>사이트 이용안내</span></Accordion.Header>
                <Accordion.Body>
                    <ul style={{textAlign: 'start'}}>
                        <li>'별나무합주실'을 누르면 이 페이지로 돌아옵니다.</li>
                        <br/>
                        <li>회원가입하여 로그인 하신 후에 '예약하기' 버튼을 누르면 예약화면으로 이동합니다.</li>
                        <br/>
                        <li>핸드폰의 경우 가로모드로 보시면 예약현황을 보다 편하게 보실 수 있습니다.</li>
                        <br/>
                        <li>예약화면 상단의 날짜버튼을 눌러 원하는 날짜를 선택하시고 해당일의 예약현황을 확인하실 수 있습니다.</li>
                        <br/>
                        <li>폰 세로모드 화면에서는 손가락으로 화면을 좌우방향으로 쓸어넘겨서 각 룸의 예약현황을 확인하실 수 있습니다.</li>
                        <br/>
                        <li>'예약가능'으로 표시된 시간 중 원하는 시간을 누르시면 해당 시간이 선택되고 화면 하단에 예약내용이 나타납니다.</li>
                        <br/>
                        <li>선택된 시간을 다시 눌러서 선택을 해제할 수 있습니다.</li>
                        <br/>
                        <li>예약내용을 확인하시고 예약/변경/취소신청하기 버튼을 눌러서 예약을 진행하시면 됩니다.</li>
                        <br/>
                        <li>예약을 추가하거나 변경한 다음에는 꼭 예약/변경/취소신청하기 버튼을 눌러야 예약내용이 갱신됩니다.</li>
                        <br/>
                        <li>예약을 취소하거나 변경했는데 문자가 오지 않는다면 사이트홈에 돌아가서 다시 예약하기를 누르고 예약내용을 꼭 확인해주세요.</li>
                        <br/>
                        <li>당일예약, 혹은 전일에 예약을 변경하여 합주실을 열고 닫는 시간이 바뀔 경우, 근무자 섭외 문제때문에 전화나 문자로만 예약문의가 가능합니다.</li>
                        <br/>
                        <li>당일과 전일 예약변경/취소는 전화와 문자로만 가능합니다.</li>
                        <br/>
                        <li>유지할 수 있는 예약건수가 5건으로 제한되어 있습니다. 6건부터는 전화로 예약문의 해주세요</li>
                    </ul>
                   
                
                
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header><span style={{fontWeight: 'bold'}}>결제방법 안내</span></Accordion.Header>
                <Accordion.Body>
                    <ul style={{textAlign: 'start'}}>
                        <li>당일 이용시에 카드, 현금, 계좌이체로 결제하실 수 있습니다.</li>
                        <br/>
                        <li>입금 계좌번호: <br/>우리은행 125-132319-02-103<br/> 예금주: 김태윤</li>
                        <br/>
                        <li>입금하실때 입급계좌에 표시될 내용에 '예약일 예약명'으로 입력하시면 확인에 도움이 됩니다.<br/>예) 5월5홍길동</li>
                        <br/>
                        <li> 세금계산서 등 기타 결제관련 문의사항은 하단의 연락처로 문의바랍니다.</li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header><span style={{fontWeight: 'bold'}}>취소위약금 안내</span></Accordion.Header>
                <Accordion.Body>
                    <ul style={{textAlign: 'start'}}>
                        <li>예약일이 임박하여 취소하는 경우가 잦아지면 다른분들의 합주실 이용과 합주실 운영에 지장이 초래되는 관계로, 예약일의 전날이나 예약 당일에 취소하실 경우 취소위약금을 부과하고 있습니다.</li>
                        <br/>
                        <li>전날 취소하시면 이용금액의 70%, 당일에 취소하시면 이용금액의 100%가 위약금으로 부과됩니다.</li>
                        <br/>
                        <li>전날취소와 당일취소의 경우 전화와 문자로만 취소가 가능합니다.</li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header><span style={{fontWeight: 'bold'}}>합주실 이용시 주의사항</span> </Accordion.Header>
                <Accordion.Body>
                    <ul style={{textAlign: 'start'}}>
                        <li>합주실 퇴실 전에는 반드시 사용하신 장비를 원상태로 복구해 주셔야 합니다</li>
                        <br/>
                        <li>특히 드럼의 경우, 세팅을 많이 바꿔 놓으면 다음 이용팀이 다시 세팅하는데 오랜 시간이 소요되고 불편을 드리기 때문에, 꼭 원상복구해주셔야 합니다</li>
                        <br/>
                        <li>합주실 이용시간에는 합주를 위한 세팅시간과 이를 원상복구시키는 시간이 포함됩니다. 이 점 감안하여 다음 합주팀에 피해를 주지 않도록 합주실 이용해 주시기를 당부드립니다.</li>
                        <br/>
                        <li>렌트하신 장비를 무리하게 변형하시거나 파손하실 시 배상을 청구할 수 있습니다</li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header><span style={{fontWeight: 'bold'}}>차음문 닫는법</span></Accordion.Header>
                <Accordion.Body>
                    <ul style={{textAlign: 'start'}}>
                        <li>합주실 차음문은 일반적인 녹음실과 반대로, 가로로 두면 열리고 아래로 내려야 잠깁니다.</li>
                        <br/>
                        <li>간혹 무리하게 힘줘서 위로 밀어올리는 분들이 계신데, 자꾸 그러시면 문 잠금장치가 고장납니다. ㅠㅠ</li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header><span style={{fontWeight: 'bold'}}>주차안내</span></Accordion.Header>
                <Accordion.Body>
                    <ul style={{textAlign: 'start'}}>
                        <li>큰 방은 두 대, 작은 방은 한 대 정도 이용하실 수 있습니다.</li>
                        <br/>
                        <li>하지만 건물 전체가 사용하는 공간이라, 항상 주차자리가 있다고 보장해 드릴 수는 없습니다.</li>
                        <br/>
                        <li>한 팀이 너무 많은 차량을 주차하면 다른 팀이 이용할 수 없는 상황이 생길 수 있습니다. 
                            다른 분들을 배려하여 팀원끼리 사전에 조율을 통해 차량 이용을 최소화 해주시면 감사하겠습니다.</li>
                        <br/>
                        <li>다른 분들의 이용을 위해서 합주실 이용 후에는 바로 출차하셔야 합니다.</li>
                        <br/>
                        <li>인근 유료주차장: 서교동371-17, 서교동371-19 두 주차장이 서로 인접합니다. 합주실에서 도보 약 3분거리 직선거리 250m정도입니다. 합주실 바로 맞은편 건물에도 유료주차 가능합니다.</li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

function StarNoti(props) {
    return (
        <div className="marquee">
            <p>{props.content}</p>
        </div>
    );
}

function RoomCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel variant='dark' interval={5000} indicators={false} activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
        <Card className="roomImage" style={{backgroundColor: '#212529', color: 'white'}}>
            <Card.Img variant="top" src={require('../images/V.jpeg')} />
            <Card.Body>
                <Card.Title>V룸 13평 21000원/시간</Card.Title>
                <Card.Text style={{fontSize: '1.6vh'}}>
                    <span style={{fontWeight: 'bolder'}}>Guitar&nbsp;&nbsp;</span> 
                    Marshall JCM2000DSL/1960 x2대,<br/> 
                    Fender HotRod Deluxe<br/>
                    <span style={{fontWeight: 'bolder'}}>Bass&nbsp;&nbsp;</span> 
                    Ampeg SVT4pro/810<br/>
                    <span style={{fontWeight: 'bolder'}}>Drum&nbsp;&nbsp;</span> 
                    Pearl MCX<br/>
                    <span style={{fontWeight: 'bolder'}}>Keyb&nbsp;&nbsp;</span> 
                    Yamaha S90XS, Yamaha Motif XF7<br/>
                    <br/>
                </Card.Text>
            </Card.Body>
        </Card>
      </Carousel.Item>
      <Carousel.Item>
        <Card className="roomImage" style={{backgroundColor: '#212529', color: 'white'}}>
            <Card.Img variant="top" src={require('../images/R.jpeg')} />
            <Card.Body>
                <Card.Title>R룸 10평 19000원/시간</Card.Title>
                <Card.Text style={{fontSize: '1.6vh'}}>
                    <span style={{fontWeight: 'bolder'}}>Guitar&nbsp;&nbsp;</span> 
                    Marshall JCM2000DSL/1960,<br/> 
                    Mesa Dual, Fender Twin Reverb,<br/>
                    Marshall JCM2000 TSL combo<br/>
                    <span style={{fontWeight: 'bolder'}}>Bass&nbsp;&nbsp;</span>
                    Ampeg SVT4pro/810<br/>
                    <span style={{fontWeight: 'bolder'}}>Drum&nbsp;&nbsp;</span> 
                    Premire Genista Birch(1995)<br/>
                    <span style={{fontWeight: 'bolder'}}>Keyb&nbsp;&nbsp;</span> 
                    Yamaha S90XS, Yamaha Motif XF7<br/>
                </Card.Text>
            </Card.Body>
        </Card>
      </Carousel.Item>
      <Carousel.Item>
        <Card className="roomImage" style={{backgroundColor: '#212529', color: 'white'}}>
            <Card.Img variant="top" src={require('../images/Y.jpeg')} />
            <Card.Body>
                <Card.Title>Y룸 10평 18000원/시간</Card.Title>
                <Card.Text style={{fontSize: '1.6vh'}}>
                    <span style={{fontWeight: 'bolder'}}>Guitar&nbsp;&nbsp;</span> 
                    Marshall JCM2000DSL/1960,<br/> 
                    Mesa Triple,<br/>
                    Peavy 5150<br/>
                    <span style={{fontWeight: 'bolder'}}>Bass&nbsp;&nbsp;</span>
                    Mark Bass Little Mark3/104HLF<br/>
                    <span style={{fontWeight: 'bolder'}}>Drum&nbsp;&nbsp;</span> 
                    Pearl MCX<br/>
                    <span style={{fontWeight: 'bolder'}}>Keyb&nbsp;&nbsp;</span> 
                    Yamaha S90XS, Yamaha Motif7<br/>
                </Card.Text>
            </Card.Body>
        </Card>
      </Carousel.Item>
      <Carousel.Item>
        <Card className="roomImage" style={{backgroundColor: '#212529', color: 'white'}}>
            <Card.Img variant="top" src={require('../images/T.jpeg')} />
            <Card.Body>
                <Card.Title>T룸 6평 14000원/시간</Card.Title>
                <Card.Text style={{fontSize: '1.6vh'}}>
                    <span style={{fontWeight: 'bolder'}}>Guitar&nbsp;&nbsp;</span> 
                    Marshall DSL100H/1960 x2대<br/>     
                    <span style={{fontWeight: 'bolder'}}>Bass&nbsp;&nbsp;</span>
                    Mark Bass Cmd 102P<br/>
                    <span style={{fontWeight: 'bolder'}}>Drum&nbsp;&nbsp;</span> 
                    Pearl Birch<br/>
                    <span style={{fontWeight: 'bolder'}}>Keyb&nbsp;&nbsp;</span> 
                    PC1X<br/>
                    <br/><br/>
                </Card.Text>
            </Card.Body>
        </Card>
      </Carousel.Item>
      <Carousel.Item>
        <Card className="roomImage" style={{backgroundColor: '#212529', color: 'white'}}>
            <Card.Img variant="top" src={require('../images/J.jpeg')} />
            <Card.Body>
                <Card.Title>J룸 6평 14000원/시간</Card.Title>
                <Card.Text style={{fontSize: '1.6vh'}}>
                    <span style={{fontWeight: 'bolder'}}>Guitar&nbsp;&nbsp;</span> 
                    Marshall DSL100H/1960 x2대<br/>     
                    <span style={{fontWeight: 'bolder'}}>Bass&nbsp;&nbsp;</span>
                    Mark Bass Little Mark3/104HLF<br/>
                    <span style={{fontWeight: 'bolder'}}>Drum&nbsp;&nbsp;</span> 
                    Pearl Birch<br/>
                    <span style={{fontWeight: 'bolder'}}>Keyb&nbsp;&nbsp;</span> 
                    PC1X<br/>
                    <br/><br/>
                </Card.Text>
            </Card.Body>
        </Card>
      </Carousel.Item>
    </Carousel>
  );
}

export default Root;

